<template>
    <div class="wrap-404">
        <div class="content">
            <img class="image" src="~assets/404.png">
            <p class="text">抱歉！你访问的页面不存在</p>
            <div class="btn-404 pointer" @click="goTo2('spaces')">重新加载页面</div>
        </div>
    </div>  
</template>
<script>
    import { navigation } from '@/mixins/index'

    export default {
        name: "",
        mixins: [navigation],
        components: {},
        props: [],
        data () {
            return {}
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>
<style scoped lang="less">
@import '~less/color';

.wrap-404 {
    height: 100%;
    .content {
        display: inline-block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        .image {
            display: inline-block;
        }
        .text {
            margin: 54px 0;
            font-size: 32px;
            text-align: center;
        }
        .btn-404 {
            display: inline-block;
            padding: 0 54px;
            font-size: 18px;
            line-height: 50px;
            color: #fff;
            background: @primary-color;
            text-align: center;
            border-radius: 4px;
        }
    }
}
</style>